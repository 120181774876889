var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateUser()
        },
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 mt-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-navbar",
                { staticClass: "sidenav", attrs: { fixed: "top" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { "align-v": "center" },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "d-md-none text-center",
                              attrs: { cols: "6" },
                            },
                            [_vm._v("Personal Details")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "d-md-none text-right",
                              attrs: { cols: "3" },
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "step text-uppercase mb-0" },
                                [_vm._v("Step 1 of 2")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "b-row",
                {
                  staticClass: "d-none d-sm-none d-md-flex",
                  staticStyle: {
                    position: "fixed",
                    height: "calc(100vh - 300px)",
                  },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-primary",
                      attrs: { cols: "12", "align-self": "start" },
                    },
                    [_vm._v(" Personal Details ")]
                  ),
                  _c("b-col", { attrs: { cols: "12", "align-self": "end" } }, [
                    _c("h6", { staticClass: "step text-uppercase" }, [
                      _vm._v("Step 1 of 2"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "7" } },
            [
              _c("h1", { staticClass: "mb-6 heading" }, [
                _vm._v("Let’s start with the basics."),
              ]),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "firstName" } }, [
                          _vm._v("First Name:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "firstName",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.firstName,
                            callback: function ($$v) {
                              _vm.firstName = $$v
                            },
                            expression: "firstName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "lastName" } }, [
                          _vm._v("Last Name:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "lastName",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.lastName,
                            callback: function ($$v) {
                              _vm.lastName = $$v
                            },
                            expression: "lastName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c("label", { attrs: { for: "birthday" } }, [
                      _vm._v("Birthday:"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "input-group mb-4",
                        attrs: { role: "group" },
                      },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control pl-0",
                          staticStyle: { width: "0" },
                          attrs: { config: _vm.wrap, disabled: _vm.processing },
                          model: {
                            value: _vm.birthday,
                            callback: function ($$v) {
                              _vm.birthday = $$v
                            },
                            expression: "birthday",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "gender" } }, [
                          _vm._v("Gender:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            id: "gender",
                            "append-to-body": "",
                            "calculate-position": _vm.withPopper,
                            label: "text",
                            disabled: _vm.processing,
                            options: _vm.options,
                            reduce: (options) => options.value,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "open-indicator",
                              fn: function ({ attributes }) {
                                return [
                                  _c(
                                    "b-icon",
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "chevron-down",
                                          variant: "primary",
                                        },
                                      },
                                      "b-icon",
                                      attributes,
                                      false
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.gender,
                            callback: function ($$v) {
                              _vm.gender = $$v
                            },
                            expression: "gender",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "input-live" } }, [
                          _vm._v("Email Address:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: { id: "email", type: "email", readonly: "" },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "phone" } }, [
                          _vm._v("Phone:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "phone",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.phone,
                            callback: function ($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "input-live-feedback" } },
                          [_vm._v(" Invalid phone number format ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "stateLicense" } }, [
                          _vm._v("State License:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            id: "stateLicense",
                            "append-to-body": "",
                            "calculate-position": _vm.withPopper,
                            label: "name",
                            disabled: _vm.processing,
                            options: _vm.license,
                            reduce: (license) => license.id,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "open-indicator",
                              fn: function ({ attributes }) {
                                return [
                                  _c(
                                    "b-icon",
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "chevron-down",
                                          variant: "primary",
                                        },
                                      },
                                      "b-icon",
                                      attributes,
                                      false
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.stateLicense,
                            callback: function ($$v) {
                              _vm.stateLicense = $$v
                            },
                            expression: "stateLicense",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "fax" } }, [
                          _vm._v("Fax Number:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "fax",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.faxno,
                            callback: function ($$v) {
                              _vm.faxno = $$v
                            },
                            expression: "faxno",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "npi" } }, [
                          _vm._v("NPI Number:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "npi",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.npinumber,
                            callback: function ($$v) {
                              _vm.npinumber = $$v
                            },
                            expression: "npinumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { staticClass: "mb-7", attrs: { cols: "12" } }),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "street" } }, [
                          _vm._v("Street Address:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "street",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.address1,
                            callback: function ($$v) {
                              _vm.address1 = $$v
                            },
                            expression: "address1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "apartment" } }, [
                          _vm._v("Apartment/Suite:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "apartment",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.address2,
                            callback: function ($$v) {
                              _vm.address2 = $$v
                            },
                            expression: "address2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "city" } }, [
                          _vm._v("City:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "city",
                            type: "text",
                            disabled: _vm.processing,
                          },
                          model: {
                            value: _vm.city,
                            callback: function ($$v) {
                              _vm.city = $$v
                            },
                            expression: "city",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "state" } }, [
                          _vm._v("State:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            id: "state",
                            "append-to-body": "",
                            "calculate-position": _vm.withPopper,
                            disabled: _vm.processing,
                            label: "name",
                            options: _vm.stateOptions,
                            reduce: (stateOptions) => stateOptions.id,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "open-indicator",
                              fn: function ({ attributes }) {
                                return [
                                  _c(
                                    "b-icon",
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "chevron-down",
                                          variant: "primary",
                                        },
                                      },
                                      "b-icon",
                                      attributes,
                                      false
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.state,
                            callback: function ($$v) {
                              _vm.state = $$v
                            },
                            expression: "state",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c("label", { attrs: { for: "zip" } }, [
                          _vm._v("Zip Code:"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "pl-0",
                          attrs: {
                            id: "zip",
                            disabled: _vm.processing,
                            type: "text",
                          },
                          model: {
                            value: _vm.zipcode,
                            callback: function ($$v) {
                              _vm.zipcode = $$v
                            },
                            expression: "zipcode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: {
                            size: "lg",
                            pill: "",
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.processing,
                          },
                        },
                        [_vm._v(_vm._s(_vm.processing ? "Saving" : "Next"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }