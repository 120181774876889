<template>
  <b-form @submit.prevent="updateUser()">
    <b-row align-h="center" class="mb-5 mt-4">
      <!-- half mobile half desktop -->
      <b-col cols="12">
        <b-navbar fixed="top" class="sidenav">
          <b-container fluid>
            <b-row align-v="center" style="width: 100%">
              <!-- <b-col cols="3">
                <b-button class="back-btn" size="lg" to="/personal-details" variant="link"
                  ><img src="@/assets/images/back_arrow.svg" alt=""
                /></b-button>
              </b-col> -->
              <b-col cols="6" class="d-md-none text-center"
                >Personal Details</b-col
              >
              <b-col cols="3" class="d-md-none text-right">
                <h6 class="step text-uppercase mb-0">Step 1 of 2</h6>
              </b-col>
            </b-row>
          </b-container>
        </b-navbar>
      </b-col>
      <!-- desktop view -->
      <b-col cols="12" md="3">
        <b-row
          class="d-none d-sm-none d-md-flex"
          style="position: fixed; height: calc(100vh - 300px)"
        >
          <b-col cols="12" align-self="start" class="text-primary">
            Personal Details
          </b-col>
          <b-col cols="12" align-self="end">
            <h6 class="step text-uppercase">Step 1 of 2</h6>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="7">
        <!-- Title -->
        <h1 class="mb-6 heading">Let’s start with the basics.</h1>
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
        <b-row>
          <!-- First Name -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="firstName">First Name:</label>

              <b-form-input
                id="firstName"
                class="pl-0"
                type="text"
                v-model="firstName"
                :disabled="processing"
              ></b-form-input>
            </div>
          </b-col>
          <!-- Last Name -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="lastName">Last Name:</label>

              <b-form-input
                id="lastName"
                class="pl-0"
                type="text"
                v-model="lastName"
                :disabled="processing"
              ></b-form-input>
            </div>
          </b-col>
          <!-- Birthday -->
          <b-col cols="12" sm="6" md="6">
            <label for="birthday">Birthday:</label>
            <div class="input-group mb-4" role="group">
              <!-- <b-form-input
                id="birthday"
                class="pl-0"
                type="text"
                v-model="birthday"
                placeholder="MM/DD/YYYY"
                autocomplete="off"
              ></b-form-input> -->

              <flat-pickr
                class="form-control pl-0"
                style="width: 0"
                :config="wrap"
                :disabled="processing"
                v-model="birthday"
              ></flat-pickr>

              <!-- <div class="input-group-append">
                
                <b-input-group-text>
                  <b-icon icon="calendar3" variant="primary" data-toggle></b-icon>
                </b-input-group-text>
              </div> -->
            </div>
          </b-col>
          <!-- Gender -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="gender">Gender:</label>
              <v-select
                id="gender"
                append-to-body
                :calculate-position="withPopper"
                v-model="gender"
                label="text"
                :disabled="processing"
                :options="options"
                :reduce="(options) => options.value"
              >
                <template #open-indicator="{ attributes }">
                  <b-icon
                    v-bind="attributes"
                    icon="chevron-down"
                    variant="primary"
                  ></b-icon>
                </template>
              </v-select>
              <!-- <b-form-select
                id="gender"
                class="pl-0"
                v-model="gender"
                :options="options"
              ></b-form-select> -->
            </div>
          </b-col>
          <!-- Email -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="input-live">Email Address:</label>
              <b-form-input
                id="email"
                class="pl-0"
                type="email"
                v-model="email"
                readonly
              ></b-form-input>
            </div>
          </b-col>
          <!-- Phone -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="phone">Phone:</label>

              <b-form-input
                id="phone"
                class="pl-0"
                type="text"
                v-model="phone"
                :disabled="processing"
              ></b-form-input>

              <b-form-invalid-feedback id="input-live-feedback">
                Invalid phone number format
              </b-form-invalid-feedback>
            </div>
          </b-col>
          <!-- State License -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="stateLicense">State License:</label>
              <v-select
                id="stateLicense"
                append-to-body
                :calculate-position="withPopper"
                v-model="stateLicense"
                label="name"
                :disabled="processing"
                :options="license"
                :reduce="(license) => license.id"
              >
                <template #open-indicator="{ attributes }">
                  <b-icon
                    v-bind="attributes"
                    icon="chevron-down"
                    variant="primary"
                  ></b-icon>
                </template>
              </v-select>
              <!-- <b-form-input
                  id="stateLicense"
                  class="pl-0"
                  type="text"
                  v-model="stateLicense"
                ></b-form-input> -->
            </div>
          </b-col>
          <!-- Fax -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="fax">Fax Number:</label>

              <b-form-input
                id="fax"
                class="pl-0"
                type="text"
                :disabled="processing"
                v-model="faxno"
              ></b-form-input>
            </div>
          </b-col>
          <!-- NPI -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="npi">NPI Number:</label>

              <b-form-input
                id="npi"
                class="pl-0"
                type="text"
                :disabled="processing"
                v-model="npinumber"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" class="mb-7"></b-col>
          <!-- Street -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="street">Street Address:</label>

              <b-form-input
                id="street"
                class="pl-0"
                type="text"
                :disabled="processing"
                v-model="address1"
              ></b-form-input>
            </div>
          </b-col>
          <!-- Apartment/Suite -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="apartment">Apartment/Suite:</label>

              <b-form-input
                id="apartment"
                class="pl-0"
                type="text"
                :disabled="processing"
                v-model="address2"
              ></b-form-input>
            </div>
          </b-col>
          <!-- City -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="city">City:</label>

              <b-form-input
                id="city"
                class="pl-0"
                type="text"
                :disabled="processing"
                v-model="city"
              ></b-form-input>
            </div>
          </b-col>
          <!-- State -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="state">State:</label>
              <v-select
                id="state"
                append-to-body
                :calculate-position="withPopper"
                :disabled="processing"
                v-model="state"
                label="name"
                :options="stateOptions"
                :reduce="(stateOptions) => stateOptions.id"
              >
                <template #open-indicator="{ attributes }">
                  <b-icon
                    v-bind="attributes"
                    icon="chevron-down"
                    variant="primary"
                  ></b-icon>
                </template>
              </v-select>
            </div>
          </b-col>
          <!-- Zip Code -->
          <b-col cols="12" sm="6" md="6">
            <div role="group" class="mb-4">
              <label for="zip">Zip Code:</label>
              <b-form-input
                id="zip"
                class="pl-0"
                :disabled="processing"
                type="text"
                v-model="zipcode"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" class="text-right mt-5">
            <b-button
              size="lg"
              pill
              type="submit"
              variant="primary"
              :disabled="processing"
              style="min-width: 150px"
              >{{ processing ? "Saving" : "Next" }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12" md="12">
        <next-step step-number="1"></next-step>
      </b-col>
    </b-row> -->
  </b-form>
</template>
<script>
import { OnboardingService } from "@/services/onboarding.service";
import popper from "@/assets/js/popper.js";

// import nextStep from "@/components/step/StepButton";
import formValidation from "@/components/form/mixins";
export default {
  name: "user-info",
  mixins: [formValidation, popper],
  // components: {
  //   nextStep,
  // },
  data() {
    return {
      stateOptions: [],
      license: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      npinumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      faxno: "",
      birthday: "",
      gender: null,
      stateLicense: "",
      error: "",
      processing: false,
      options: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      wrap: {
        wrap: true,
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
        minDate: this.moment().subtract(100, "year").format("YYYY-MM-DD"),
        maxDate: this.moment().subtract(18, "year").format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    alertMessage(type, message) {
      window.scrollTo(0, 0);

      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },

    async updateUser() {
      try {
        this.processing = true;
        this.alert.show = false;
        // insert personal details axios here
        let data = {
          firstname: this.firstName,
          lastname: this.lastName,
          phoneno: this.phone,
          birthday: this.birthday,
          gender: this.gender,
          address1: this.address1,
          address2: this.address2,
          state: this.state,
          licensestate: this.stateLicense,
          zipcode: this.zipcode,
          city: this.city,
          npinumber: this.npinumber,
          faxno: this.faxno,
        };

        await OnboardingService.updateDoctorOnboarding(1, data);

        this.$router.push("/upload-document");
      } catch (error) {
        this.error =
          error.status === 404
            ? "User with same email not found"
            : error.message;
        this.alertMessage("warning", this.error, 15);
      } finally {
        this.processing = false;
      }
    },

    async listState() {
      try {
        let states = await OnboardingService.lookUp("state");
        this.stateOptions = states.data.filter(function (o) {
          return o.supported == true;
        });
        this.license = states.data;
      } catch (error) {
        console.log(error);
      }
    },
    getuser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {
          if (data.onboardingstatus === "DONE") return this.$router.push("/");

          this.firstName = data.firstname;
          this.lastName = data.lastname;
          this.email = data.email;
          this.phone = data.phoneno;
          this.birthday = data.birthday;
          this.address1 = data.address1;
          this.address2 = data.address2 || "";
          this.state = data.state;
          this.stateLicense = data.licensestate;
          this.city = data.city;
          this.zipcode = data.zipcode;
          this.npinumber = data.npinumber;
          this.faxno = data.faxno;

          if (data.gender) this.gender = data.gender;
        })
        .catch((error) => {
          if (error.message) {
            return this.alertMessage("warning", error.message, 15);
          }
        });
    },
  },
  mounted() {
    this.getuser();
    this.listState();
  },
};
</script>

<style lang="scss" scoped>
// .flatpickr-input .form-control {
//   width: 0;
// }
</style>
